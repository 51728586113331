<template>
	<div>
		<p v-html="content"></p>
	</div>
</template>

<script>
export default {
	name: "Info",
	data(){
		return {
			id: '',
			content: '',
		}
	},
	mounted() {
		let id = this.$route.params.id
		this.id = id
		this.loadData(id)
		console.log(id);
	},
	beforeCreate () {
		document.querySelector('body').setAttribute('style', 'background-color:#fff!important;');
	},
	beforeDestroy() {
		document.querySelector('body').setAttribute('style', 'background-color:#000000!important;');
	},
	methods:{
		loadData(id){
			this.request({
				url: `/api/getArticlesDetail/${id}`
			}).then(res=>{
				console.log(res,'=====getArticlesDetail======');
				if (0==res.code){
					this.content = res.data.content
				}
			})
		}
	},
}
</script>

<style>
	/*body{*/
	/*	background-color: #fff;*/
	/*}*/
</style>
